import React, { useEffect, useState } from 'react'
import { FiDownload, FiUpload } from 'react-icons/fi'
import { Tooltip } from 'react-tooltip'
import { Button } from 'antd'
import { TailSpin } from 'react-loader-spinner'
import AlertsTable from './components/AlertsTable'
import { useAlert } from '../Layout/baseContext/alertsContext/AlertsContext'
import DFUAlertsTable from './components/DFUAlertsTable'

const Alerts = () => {
    const { selectedArea, setSelectedArea, areaOptions, loading, setSelectedTable, alertsData, showDFUonly } = useAlert();

    // const setTableData = () => {
    //     console.log('Original alertsData:', alertsData);

    //     const filteredData = alertsData.filter((item) => {
    //         const currentDate = new Date();
    //         const itemDate = new Date(item.SDATE);

    //         console.log('SDATE:', item.SDATE);
    //         console.log('Parsed Date:', itemDate);

    //         if (isNaN(itemDate)) {
    //             console.log('Invalid Date:', item.SDATE);
    //             return false; // Skip this item if SDATE is not a valid date
    //         }

    //         switch (selectedArea) {
    //             case 'New Exceptions':
    //                 return itemDate >= new Date(currentDate.setDate(currentDate.getDate() - 30));
    //             case 'Last Period Exceptions':
    //                 const lastMonth = new Date();
    //                 lastMonth.setMonth(currentDate.getMonth() - 1);

    //                 return (
    //                     itemDate.getFullYear() === lastMonth.getFullYear() &&
    //                     itemDate.getMonth() === lastMonth.getMonth()
    //                 );
    //             case 'Current Year':
    //                 return itemDate.getFullYear() === new Date().getFullYear();
    //             case 'Older Exceptions':
    //                 return itemDate.getFullYear() < new Date().getFullYear();
    //             default:
    //                 return false;
    //         }

    //     });

    //     console.log('Filtered Data:', filteredData);

    //     setSelectedTable(filteredData);
    // };


    // useEffect(() => {
    //     setTableData()
    // }, [selectedArea]);

    

    if (loading) {
        return (
            <div className='h-full flex justify-center items-center'>
                <TailSpin
                    visible={true}
                    height="40"
                    width="40"
                    color="#2B3674"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        );
    }

    return (
        <div className='flex flex-col space-y-2 overflow-scroll w-full h-full '>
            <div className='flex flex-row justify-between'>
                <div className='flex flex-row items-center justify-between w-full'>
                    <div className='flex flex-row w-fit bg-white shadow-sm rounded-full px-1 text-xs py-1'>
                        {areaOptions.map((item) => (
                            <div
                                key={item}
                                className={`text-left px-2 py-1 rounded-full transition-all ease-in-out duration-300 cursor-pointer font-semibold  ${item === selectedArea ? 'text-white bg-blue-500 ' : 'text-gray-500 bg-white'}`}
                                onClick={() => setSelectedArea(item)}
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <div className="flex flex-row  items-center border border-slate-300 hover:bg-slate-100 p-2 rounded-full cursor-pointer text-slate-400 font-bold text-lg" data-tooltip-id="my-tooltip" data-tooltip-content="Download">
                            <FiDownload />
                        </div>
                        <div className="flex flex-row  items-center  p-2 border border-slate-300  hover:bg-slate-100 rounded-full cursor-pointer text-slate-400 text-lg font-bold" data-tooltip-id="my-tooltip" data-tooltip-content="Upload">
                            <FiUpload />
                        </div>
                    </div>
                </div>
            </div>
            {
                showDFUonly ?
                    <DFUAlertsTable />
                    :
                    <AlertsTable />
            }

            <Tooltip id="my-tooltip" />

        </div>
    );
}

export default Alerts;
