import { Button, DatePicker, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import ForecastHelper from './ForecastHelper';
import { usePortfolio } from '../../Layout/baseContext/portfolioContext/PortfolioContext';
import moment from 'moment';
import dayjs from 'dayjs';
import { useBase } from '../../Layout/baseContext/BaseContext';
import api from '../../../api';
import { useDashboard } from '../../Layout/baseContext/dashboardContext/DashboardContext';

const DiscontinueForm = ({ customerOptions, locationOptions, productOptions }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { setDisContinueForm, selectedChip } = usePortfolio();
    const { fetchActivities } = useDashboard();

    const [formData, setFormData] = useState({
        productKey: 'ITEM',
        locationKey: 'LOCATION',
        customerKey: 'CUSTOMER',
        products: [],
        customers: [],
        locations: [],
        stopDate: null,
        depletionDate: null,
    });

    const handleSelectChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (name, dateString) => {
        setFormData({ ...formData, [name]: dateString });
    };
    const handleSelectChangeKey = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const { products, customers, locations, stopDate, depletionDate } = formData;
        if (products.length === 0 && customers.length === 0 && locations.length === 0) {
            messageApi.warning("Please select at least one field.");
            return false;
        }

        return true;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return; // Exit if form validation fails
        }

        const generateActivityId = () => {
            return moment().format('YYYYMMDDHHmmssSSS'); // Format: YYYYMMDDHHmmssSSS (milliseconds included)
        };
        const activityId = generateActivityId();
        const userID = localStorage.getItem("userid");

        // Format dates by splitting at 'T' and taking the first part
        const formattedData = {
            ...formData,
            activityId,
            userID
        };

        // Handle form submission

        try {
            const response = await api.post("/api/u1_discontinued_profile/", formattedData);

            if (response.status === 401) {
                messageApi.error("Token Expired, Reload the page");
            }
            else {
                fetchActivities()
                messageApi.success("Discontinued succesfully")
                setFormData({
                    productKey: 'ITEM',
                    locationKey: 'LOCATION',
                    customerKey: 'CUSTOMER',
                    products: [],
                    customers: [],
                    locations: [],
                    stopDate: null,
                    depletionDate: null,
                })
            }
          

        } catch (error) {
            console.log(error);
            messageApi.error("Token Expired, Reload the page")
        }

    };

    const { uiMappingData } = useBase()

    const itemList = Object.keys(uiMappingData[0]).filter((item) => item.toString().substring(0, 1) === "I" && uiMappingData[0][item] !== null)
    const locationList = Object.keys(uiMappingData[0]).filter((item) => item.toString().substring(0, 1) === "L" && uiMappingData[0][item] !== null)
    const customerList = Object.keys(uiMappingData[0]).filter((item) => item.toString().substring(0, 1) === "C" && uiMappingData[0][item] !== null)

    const [itemValueList, setItemValueList] = useState({});
    const [locationValueList, setLocationValueList] = useState({});
    const [customerValueList, setCustomerValueList] = useState({});

    // General function to fetch unique values from filters
    const getUniqueValues = (filterKey, keyValue) => {
        const filters = localStorage.getItem(filterKey);
        if (!filters) return [];

        // Parse the JSON string from localStorage
        const parsedFilters = JSON.parse(filters);

        // Extract unique values for the given keyValue
        return [...new Set(parsedFilters.map(item => item[keyValue]))];
    };

    useEffect(() => {
        if (formData.productKey) {
            const data = getUniqueValues("productFilters", formData.productKey);
            const productOptions = data.map(product => ({
                value: product,
                label: product
            }));
            setItemValueList(productOptions);
        }
    }, [formData.productKey]);

    useEffect(() => {
        if (formData.locationKey) {
            const data = getUniqueValues("locationFilters", formData.locationKey);
            const locationOptions = data.map(location => ({
                value: location,
                label: location
            }));
            setLocationValueList(locationOptions);
        }
    }, [formData.locationKey]);

    useEffect(() => {
        if (formData.customerKey) {
            const data = getUniqueValues("customerFilters", formData.customerKey);
            const customerOptions = data.map(customer => ({
                value: customer,
                label: customer
            }));
            setCustomerValueList(customerOptions);
        }
    }, [formData.customerKey]);
   

    return (
        <form className='text-bodyTextBlue flex flex-col justify-between h-full overflow-y-scroll px-6 p-4 relative' onSubmit={handleSubmit}>
            {contextHolder}
            <div>
                <div>
                    <h1 className='text-lg font-bold'>
                        Discontinue a
                        {selectedChip === 'products' && ` ${uiMappingData[0]['ITEM']}`}
                        {selectedChip === 'customers' && ` ${uiMappingData[0]['CUSTOMER']}`}
                        {selectedChip === 'locations' && ` ${uiMappingData[0]['LOCATION']}`}
                    </h1>
                </div>
                <div className='flex gap-5 mt-4'>
                    <div className='flex flex-col gap-4 h-full basis-1/2'>
                        <div className='flex flex-col gap-1 justify-between'>
                            <select
                                onChange={(e) => handleSelectChangeKey('productKey', e.target.value)} // Set productKey
                                className='outline-none mr-2 cursor-pointer hover:text-blue-500'>
                                {itemList.map((item, index) => (
                                    <option key={index} value={item} className='text-inactiveText'>
                                        {uiMappingData[0][item]}
                                    </option>
                                ))}
                            </select>
                            <Select

                                className='selectTag'
                                placeholder="Select"
                                style={{
                                    height: "2.5rem",
                                    borderRadius: "2rem",
                                    margin: "0",
                                }}
                                value={formData.products}
                                onChange={(value) => handleSelectChange('products', value)}
                                mode='multiple'
                                maxTagCount='responsive'
                                options={itemValueList}
                            />
                        </div>
                        <div className='flex flex-col gap-1 justify-between'>
                            <select
                                onChange={(e) => handleSelectChangeKey('locationKey', e.target.value)} // Set productKey
                                className='outline-none mr-2 cursor-pointer hover:text-blue-500'>
                                {locationList.map((item, index) => (
                                    <option key={index} value={item} className='text-inactiveText'>
                                        {uiMappingData[0][item]}
                                    </option>
                                ))}
                            </select>
                            <Select
                                className='selectTag'
                                placeholder="Select"
                                style={{
                                    height: "2.5rem",
                                    borderRadius: "2rem",
                                    margin: "0",
                                }}
                                value={formData.locations}
                                onChange={(value) => handleSelectChange('locations', value)}
                                mode='multiple'
                                maxTagCount='responsive'
                                options={locationValueList}
                            />
                        </div>
                        <div className='flex flex-col gap-1 justify-between'>
                            <select
                                onChange={(e) => handleSelectChangeKey('customerKey', e.target.value)} // Set productKey
                                className='outline-none mr-2 cursor-pointer hover:text-blue-500'>
                                {customerList.map((item, index) => (
                                    <option key={index} value={item} className='text-inactiveText'>
                                        {uiMappingData[0][item]}
                                    </option>
                                ))}
                            </select>
                            <Select
                                className='selectTag'
                                placeholder="Select"
                                style={{
                                    height: "2.5rem",
                                    borderRadius: "2rem",
                                    margin: "0",
                                }}
                                value={formData.customers}
                                onChange={(value) => handleSelectChange('customers', value)}
                                mode='multiple'
                                maxTagCount='responsive'
                                options={customerValueList}
                            />
                        </div>

                        <div className='flex flex-col gap-1 justify-between'>
                            <label htmlFor="date">Stop Date</label>
                            <DatePicker
                                className='rounded-md m-0 bg-slate-100 py-2 border-blue-100'
                                value={(formData.stopDate) ? dayjs(formData.stopDate) : null}

                                onChange={(date, dateString) => handleDateChange('stopDate', dateString)}
                            />
                        </div>
                        <div className='flex flex-col gap-1 justify-between'>
                            <label htmlFor="date">Inventory Depletion Date</label>
                            <DatePicker
                                className='rounded-md m-0 bg-slate-100 py-2 border-blue-100'
                                value={(formData.depletionDate) ? dayjs(formData.depletionDate) : null}
                                onChange={(date, dateString) => handleDateChange('depletionDate', dateString)}
                            />
                        </div>
                    </div>
                    <div className='h-fit mt-6'>
                        <ForecastHelper />
                    </div>
                </div>
            </div>
            <div className='flex justify-between items-center w-full mt-4'>
                <div>
                    <Button type='link' onClick={() => setFormData({
                        products: [],
                        customers: [],
                        locations: [],
                        stopDate: null,
                        depletionDate: null,
                    })}>Reset form</Button>
                </div>
                <div className='flex items-center'>
                    <div>
                        <Button type='link' onClick={() => setDisContinueForm(false)}>Cancel</Button>
                    </div>
                    <div>
                        <Button type='primary' className='bg-blue-500' htmlType="submit">Discontinue</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default DiscontinueForm;
