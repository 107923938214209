import React, { useEffect, useRef } from 'react';
import { usePlan } from '../../Layout/baseContext/planningContext/PlanningContext';
import { Button, Checkbox } from 'antd';
import { useBase } from '../../Layout/baseContext/BaseContext';

const GraphEditPopUp = () => {
    const { setGraphEditPopUp , showGraphs, setShowGraphs} = usePlan();
    const { graphTypes, setGraphTypes } = useBase();
    const popupRef = useRef(null);

    // Handle graph type change
    const handleGraphTypeChange = (dataset, type) => {
        if (type === 'bar' && dataset !== 'history') {
            setGraphTypes((prev) => ({
                ...prev,
                planner: dataset === 'planner' ? 'bar' : 'line',
                budget: dataset === 'budget' ? 'bar' : 'line',
                engine: dataset === 'engine' ? 'bar' : 'line',
                open_orders: dataset === 'engine' ? 'bar': 'bar',
            }));
        } else {
            setGraphTypes((prev) => ({
                ...prev,
                [dataset]: type,
            }));
        }
    };

    // Handle checkbox change
    const handleCheckboxChange = (dataset, checked) => {
        setShowGraphs((prev) => ({
            ...prev,
            [dataset]: checked,
        }));
    };


    // Close pop-up if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setGraphEditPopUp(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [setGraphEditPopUp]);

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 text-bodyTextBlue">
            <div
                ref={popupRef}
                className="graph-setting bg-white p-7 rounded-lg box-shadow text-center border border-slate-100 transform transition-transform duration-100 ease-out animate-scale-up"
            >
                <h1 className="text-xl font-semibold mb-6">Graph Settings</h1>

                <div className="flex flex-col gap-2">
                    {['history', 'engine', 'planner', 'budget','open_orders'].map((dataset) => (
                        <div key={dataset} className="flex items-center justify-between bg-slate-100 p-2 rounded-md">
                             <Checkbox
                                checked={showGraphs[dataset]}
                                onChange={(e) => handleCheckboxChange(dataset, e.target.checked)}
                            >
                                <p className="capitalize">{dataset}</p>
                            </Checkbox>
                            <div className='flex items-center gap-2 ml-10 rounded-full border shadow-sm bg-white'>
                                <p
                                    onClick={() => handleGraphTypeChange(dataset, 'bar')}
                                    className={`${graphTypes[dataset] === 'bar' ? "bg-blue-500 rounded-full text-white" : ""} px-2 w-12 text-center cursor-pointer`}
                                >
                                    Bar
                                </p>
                                <p
                                    onClick={() => handleGraphTypeChange(dataset, 'line')}
                                    className={`${graphTypes[dataset] === 'line' ? "bg-blue-500 rounded-full text-white" : ""} px-2 w-12 text-center cursor-pointer`}
                                >
                                    Line
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center gap-2 mt-8">
                    <Button className='bg-slate-400 hover:!bg-slate-400 hover:!text-white text-white' type="link" onClick={() => setGraphEditPopUp(false)}>Cancel</Button>
                </div>
            </div>
        </div>
    );
};

export default GraphEditPopUp;